import React, { useRef } from "react";
import { Button, Menu, MenuItem, Stack, TextField } from "@mui/material";

const VoiceSettingsDrawer = ({
  handleClick,
  voice,
  voiceDisplay,
  anchorEl,
  open,
  handleClose,
  mappedVoices,
  inputValue,
  onChangeInput,
  onSubmit,
}) => {
  const buttonRef = useRef(null); // Reference for the button

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submission action
    onSubmit(); // Calls the provided onSubmit function
  };

  return (
    <Stack direction="column" spacing={3}>
      <TextField
        label="Your Input"
        variant="outlined"
        value={inputValue}
        onChange={onChangeInput}
        fullWidth
        InputLabelProps={{
          style: { color: "white", fontSize: "18px" },
        }}
        InputProps={{
          style: {
            color: "white",
            fontSize: "18px",
          },
        }}
        sx={{
          "& label.Mui-focused": {
            color: "white",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px", // Add border radius here
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
          },
          "& .MuiOutlinedInput-input": {
            paddingTop: "14px",
            paddingBottom: "14px",
          },
          "& .MuiInputLabel-outlined": {
            transform: "translate(14px, 14px) scale(1)",
          },
          "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)",
          },
        }}
        multiline
        maxRows={15}
      />

      <Button
        id="voice-button"
        variant="contained"
        aria-haspopup="true"
        size="large"
        onClick={handleClick}
        ref={buttonRef} // Attach the ref to the button
        sx={{
          backgroundColor: "transparent",
          color: "white",
          width: "100%", // Make button take full width of its container
          alignSelf: "center",
          border: "1px solid white",
          borderRadius: 2,
          fontSize: "16px",
          "&:hover": {
            backgroundColor: "white",
            borderColor: "white",
            opacity: 0.9,
          },
        }}
      >
        {voice ? `Voice : ${voiceDisplay}` : "Voices"}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        MenuListProps={{
          "aria-labelledby": "voice-button",
        }}
        PaperProps={{
          style: {
            backgroundColor: "white", // Set the dropdown background color to white
            color: "black", // Set text color to black
            width: buttonRef.current ? buttonRef.current.offsetWidth : "auto", // Set dropdown width to match button
            maxHeight: 200, // Set max height for dropdown
            overflowY: "auto", // Allow scrolling if content exceeds max height
            borderRadius: "8px", // Add border-radius for a smoother look
          },
        }}
        sx={{
          '.MuiMenuItem-root': {
                fontSize: '1.5rem',
              },
              '.MuiSelect-select': {
              fontSize: '1.5rem',
            },
        }}
      >
        {mappedVoices.map((voice, index) => (
          <MenuItem
            onClick={handleClose}
            key={index}
            id={voice.voice}
            sx={{
              color: "black", // Ensure the text color is black to contrast with the white background
              "&:hover": {
                backgroundColor: "#f5f5f5", // Add hover effect for better UX
              },
            }}
          >
            {voice.display}
          </MenuItem>
        ))}
      </Menu>
      <Button
        id="generate-button"
        variant="contained"
        size="large"
        onClick={onSubmit}
        sx={{
          alignSelf: "center",
          backgroundColor: "transparent",
          border: "2px solid white",
          borderRadius: 10,
          color: "white",
          fontSize: "16px",
          "&:hover": {
            backgroundColor: "white",
            borderColor: "white",
            color: "#011636",
            opacity: 0.9,
          },
        }}
      >
        Generate
      </Button>
    </Stack>
  );
};

export default VoiceSettingsDrawer;
